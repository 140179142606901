var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: _vm.OverlayStyle,
    attrs: {
      "id": "transaction-overlay"
    }
  }, [_vm.transaction ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function ($event) {
        return _vm.closeModal($event);
      },
      expression: "($event) => closeModal($event)"
    }],
    key: _vm.panelUpdater,
    staticClass: "transaction-panel"
  }, [_c('div', {
    staticClass: "sticky-header"
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('v-row', {
    staticClass: "header-first-row"
  }, [_c('div', {
    staticClass: "transaction-name-row"
  }, [_c('h4', {
    staticClass: "modal-title"
  }, [_vm._v(_vm._s(_vm.$t("TransactionDetails")))]), _vm.transaction ? _c('DealTypeContainer', {
    attrs: {
      "small-font": false,
      "deal-type": _vm.transaction.transactionType
    }
  }) : _vm._e()], 1), _c('div', [_c('div', {
    staticClass: "header-button-container"
  }, [_c('div', {
    staticClass: "header-button",
    on: {
      "click": function ($event) {
        _vm.printUpdater++;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/print.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "header-button-text"
  }, [_vm._v(_vm._s(_vm.$t("Print")))])]), !_vm.projectArchived && _vm.hasAccess(_vm.transaction) ? _c('div', {
    staticClass: "header-button",
    on: {
      "click": _vm.editTransaction
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/edit.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "header-button-text"
  }, [_vm._v(_vm._s(_vm.$t("Edit")))])]) : _vm._e()])])]), _c('v-row', {
    staticClass: "header-second-row"
  }, [_c('div', {
    staticClass: "project-info"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/projects.svg"),
      "alt": ""
    }
  }), _c('a', {
    staticClass: "button-small hoverable-info",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": _vm.transaction.isUnit ? _vm.path + '/property-development/' + _vm.developmentId + '/general' : _vm.path + '/projects/' + _vm.transaction.projectId + '/general'
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.openProject(_vm.transaction.projectId);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.transaction.transactionName) + " ")]), _c('div', {
    staticClass: "open-new-tab-button",
    on: {
      "click": function ($event) {
        return _vm.openProjectNewTab(_vm.transaction.projectId);
      }
    }
  }, [_c('img', {
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/open_new_tab.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t("OpenInNewTab")))])])])])], 1), !_vm.isEdit ? _c('div', {
    staticClass: "main-info"
  }, [_c('v-row', {
    staticClass: "listing-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("DealValue")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.transaction.dealValue ? _vm.$nMapper.price(_vm.transaction.dealValue, "€") : "-") + " ")])]), _c('v-row', {
    staticClass: "listing-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("BrokerageFee")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.brokerFee()) + " ")])]), _c('v-row', {
    staticClass: "listing-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("DateOfTransaction")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.transaction.transactionDate)) + " ")])]), _vm.user.access.company._id !== '5p1EOo4yqjeiYCN3R16w' ? _c('v-row', {
    staticClass: "listing-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Status")))]), _vm.transaction.status ? _c('div', {
    staticClass: "statusLabelSidepanel content-bold",
    class: {
      'paid-label ': _vm.transaction.status === 'paid',
      'not-paid-label ': _vm.transaction.status === 'not_paid'
    },
    attrs: {
      "id": 'statusLabelSidepanel' + _vm.transaction._id
    },
    on: {
      "click": function ($event) {
        return _vm.openStatusDropdown(_vm.transaction);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.formatStatus(_vm.transaction.status)) + " ")]) : _vm._e()]) : _vm._e(), _c('v-row', {
    staticClass: "listing-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("BookingDateAndFee")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(`${_vm.transaction.booking ? _vm.formatDate(_vm.transaction.booking.date) : "-"} / ${_vm.transaction.booking && _vm.transaction.booking.fee ? _vm.transaction.booking.fee + " €" : "-"}`) + " ")])]), _vm.transaction.transactionType === 'LeaseOut' ? _c('v-row', {
    staticClass: "listing-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("ValidityOfLeaseAgreement")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(`${_vm.transaction.leaseAgreement && _vm.transaction.leaseAgreement.startDate ? _vm.formatDate(_vm.transaction.leaseAgreement.startDate) : "-"} - ${_vm.transaction.leaseAgreement && _vm.transaction.leaseAgreement.endDate ? _vm.formatDate(_vm.transaction.leaseAgreement.endDate) : "-"}`) + " ")])]) : _vm._e(), _vm.transaction.transactionType === 'LeaseOut' && _vm.transaction.leaseAgreement && _vm.transaction.leaseAgreement.reminderDate ? _c('v-row', {
    staticClass: "listing-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("ContractEndReminderDate")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.transaction.leaseAgreement.reminderDate)) + " ")])]) : _vm._e(), _vm.transaction.transactionType !== 'LeaseOut' ? _c('v-row', {
    staticClass: "listing-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("ObligationsDateAndFee")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(`${_vm.transaction.obligationsContract ? _vm.formatDate(_vm.transaction.obligationsContract.date) : "-"} / ${_vm.transaction.obligationsContract && _vm.transaction.obligationsContract.fee ? _vm.transaction.obligationsContract.fee + " €" : "-"}`) + " ")])]) : _vm._e(), _vm.transaction.transactionType !== 'LeaseOut' ? _c('v-row', {
    staticClass: "listing-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("PropertyDateAndFee")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(`${_vm.transaction.propertyContract ? _vm.formatDate(_vm.transaction.propertyContract.date) : "-"} / ${_vm.transaction.propertyContract && _vm.transaction.propertyContract.fee ? _vm.transaction.propertyContract.fee + " €" : "-"}`) + " ")])]) : _vm._e(), _c('v-row', {
    staticClass: "listing-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("PremiseDeliveryDate")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.transaction.premiseDeliveryDate)) + " ")])]), _vm.transaction.creator.userId === _vm.user._id || _vm.user.isAdmin ? _c('v-row', {
    staticClass: "listing-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.transaction.transactionType !== "LeaseOut" ? _vm.$t("Seller") : _vm.$t("Landlord")) + " ")]), _c('div', _vm._l(_vm.transaction.seller, function (seller) {
    return _c('div', {
      key: seller._id,
      staticStyle: {
        "margin-bottom": "12px"
      }
    }, [seller.type === 'private' ? _c('div', [_c('p', {
      staticClass: "field-value",
      staticStyle: {
        "font-weight": "600"
      }
    }, [_vm._v(" " + _vm._s(seller.name) + " ")]), _c('p', {
      staticClass: "field-value"
    }, [_vm._v(" " + _vm._s(seller.personalCode) + " ")]), _c('p', {
      staticClass: "field-value"
    }, [_vm._v(" " + _vm._s(seller.address) + " ")])]) : _vm._e(), seller.type === 'company' ? _c('div', [_c('p', {
      staticClass: "field-value",
      staticStyle: {
        "font-weight": "600"
      }
    }, [_vm._v(" " + _vm._s(seller.companyName) + " ")]), _c('p', {
      staticClass: "field-value"
    }, [_vm._v(" " + _vm._s(seller.registryCode) + " ")]), _c('p', {
      staticClass: "field-value"
    }, [_vm._v(" " + _vm._s(seller.companyAddress) + " ")]), _c('p', {
      staticClass: "field-value"
    }, [_vm._v(" " + _vm._s(seller.signatory) + " ")])]) : _vm._e()]);
  }), 0)]) : _vm._e(), _vm.transaction.creator.userId === _vm.user._id || _vm.user.isAdmin ? _c('v-row', {
    staticClass: "listing-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.transaction.transactionType !== "LeaseOut" ? _vm.$t("Buyer") : _vm.$t("Tenant")) + " ")]), _c('div', _vm._l(_vm.transaction.buyer, function (buyer) {
    return _c('div', {
      key: buyer._id
    }, [buyer.type === 'private' ? _c('div', [_c('p', {
      staticClass: "field-value",
      staticStyle: {
        "font-weight": "600"
      }
    }, [_vm._v(" " + _vm._s(buyer.name) + " ")]), _c('p', {
      staticClass: "field-value"
    }, [_vm._v(" " + _vm._s(buyer.personalCode) + " ")]), _c('p', {
      staticClass: "field-value"
    }, [_vm._v(" " + _vm._s(buyer.address) + " ")])]) : _vm._e(), buyer.type === 'company' ? _c('div', [_c('p', {
      staticClass: "field-value",
      staticStyle: {
        "font-weight": "600"
      }
    }, [_vm._v(" " + _vm._s(buyer.companyName) + " ")]), _c('p', {
      staticClass: "field-value"
    }, [_vm._v(" " + _vm._s(buyer.registryCode) + " ")]), _c('p', {
      staticClass: "field-value"
    }, [_vm._v(" " + _vm._s(buyer.companyAddress) + " ")]), _c('p', {
      staticClass: "field-value"
    }, [_vm._v(" " + _vm._s(buyer.signatory) + " ")])]) : _vm._e()]);
  }), 0)]) : _vm._e(), _c('v-row', {
    staticClass: "comment-section"
  }, [_c('div', {
    staticClass: "comment-container"
  }, [_c('v-row', [_c('img', {
    staticStyle: {
      "width": "20px",
      "height": "20px",
      "margin-right": "12px"
    },
    attrs: {
      "src": require("@/assets/images/chat-no-bubble.svg"),
      "alt": ""
    }
  }), !_vm.transaction.comment && !_vm.editComment ? _c('div', {
    on: {
      "click": _vm.openEditComment
    }
  }, [_c('p', {
    staticClass: "link"
  }, [_vm._v(" + " + _vm._s(_vm.$t("addComment").charAt(0).toUpperCase() + _vm.$t("addComment").slice(1)) + " ")])]) : _vm._e(), _vm.editComment ? _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.transactionComment,
      expression: "transactionComment"
    }],
    ref: "commentbox",
    staticClass: "transaction-textarea content-normal",
    attrs: {
      "id": "textarea",
      "rows": "5"
    },
    domProps: {
      "value": _vm.transactionComment
    },
    on: {
      "focusout": _vm.closeEditComment,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.transactionComment = $event.target.value;
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.transaction.comment && !_vm.editComment ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column"
    },
    on: {
      "click": _vm.openEditComment
    }
  }, [_c('div', {
    staticClass: "transaction-comment-header label12 gray-text"
  }, [_c('div', [_vm._v(_vm._s(_vm.$t("Comment")))])]), _c('div', {
    staticClass: "comment-text content-normal"
  }, [_vm._v(" " + _vm._s(_vm.transaction.comment) + " ")])]) : _vm._e()])], 1)])], 1) : _vm._e()]), _vm.isEdit ? _c('div', {
    staticClass: "transaction-details-edit-container"
  }, [_c('div', {
    staticClass: "transaction-details-edit edit-brokerage-fee"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("BrokerageFee")))]), _vm.user.access.company._id !== '5p1EOo4yqjeiYCN3R16w' ? _c('div', {
    staticClass: "edit-status-subcontainer"
  }, [_c('div', {
    staticClass: "content-small content semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("Status")) + " ")]), _c('div', {
    staticClass: "edit-status-filter",
    on: {
      "click": _vm.changeTransactionPaidStatus
    }
  }, [_c('div', {
    staticClass: "status-filter-item",
    class: {
      'filter-item-not-paid': !_vm.isTransactionPaid
    },
    attrs: {
      "id": "status-not-paid"
    }
  }, [_c('p', {
    attrs: {
      "id": "status-not-paid"
    }
  }, [_vm._v(" " + _vm._s(_vm.transaction.isUnit ? _vm.$t("Incomplete") : _vm.$t("NotPaid")) + " ")])]), _c('div', {
    staticClass: "status-filter-item",
    class: {
      'filter-item-paid': _vm.isTransactionPaid
    },
    attrs: {
      "id": "status-paid"
    }
  }, [_c('p', {
    attrs: {
      "id": "status-paid"
    }
  }, [_vm._v(" " + _vm._s(_vm.transaction.isUnit ? _vm.$t("Completed") : _vm.$t("Paid")) + " ")])])])]) : _vm._e(), _c('div', [_c('v-row', {
    class: {
      'margin-up': _vm.user.access.company._id !== '5p1EOo4yqjeiYCN3R16w'
    }
  }, [_c('div', {
    staticClass: "content-small content semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("BrokerageFeeIs")) + " ")])]), _c('v-radio-group', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.transaction.brokerageFeeType,
      callback: function ($$v) {
        _vm.$set(_vm.transaction, "brokerageFeeType", $$v);
      },
      expression: "transaction.brokerageFeeType"
    }
  }, [_c('v-radio', {
    staticClass: "radio-button",
    attrs: {
      "color": "#FF5C01",
      "value": "percentOfPrice",
      "label": _vm.$t('PercentageOfFinalPrice')
    }
  }), _c('v-radio', {
    staticClass: "radio-button",
    attrs: {
      "color": "#FF5C01",
      "value": "agreedPrice",
      "label": _vm.$t('AgreedAmount')
    }
  })], 1), _vm.transaction.brokerageFeeType === 'agreedPrice' ? _c('div', {
    staticClass: "edit-brokerage-fee-row",
    staticStyle: {
      "display": "contents"
    }
  }, [_c('v-row', {
    staticStyle: {
      "gap": "12px",
      "display": "flex",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": 'brokerage-fee-field',
      "placeholder": _vm.$t('BrokerageFee'),
      "icon-right": "euroSign.svg",
      "type": "number",
      "format": 'price'
    },
    model: {
      value: _vm.transaction.brokerageFee,
      callback: function ($$v) {
        _vm.$set(_vm.transaction, "brokerageFee", $$v);
      },
      expression: "transaction.brokerageFee"
    }
  })], 1)], 1) : _vm.transaction.brokerageFeeType === 'percentOfPrice' ? _c('v-row', {
    staticClass: "edit-brokerage-fee-row"
  }, [_c('BaseInput', {
    attrs: {
      "type": "number",
      "format": 'price',
      "id": 'deal-amount-percent',
      "icon-right": "percentage.svg",
      "placeholder": _vm.$t('Percentage')
    },
    model: {
      value: _vm.transaction.brokerFeePercentage,
      callback: function ($$v) {
        _vm.$set(_vm.transaction, "brokerFeePercentage", $$v);
      },
      expression: "transaction.brokerFeePercentage"
    }
  }), _c('BaseInput', {
    attrs: {
      "type": "number",
      "format": 'price',
      "id": 'deal-amount-min-fee',
      "placeholder": _vm.$t('MinimumFee'),
      "icon-right": "euroSign.svg"
    },
    model: {
      value: _vm.transaction.minimumFee,
      callback: function ($$v) {
        _vm.$set(_vm.transaction, "minimumFee", $$v);
      },
      expression: "transaction.minimumFee"
    }
  })], 1) : _vm._e(), _c('v-row', {
    staticClass: "edit-brokerage-fee-row",
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "type": "number",
      "format": 'price',
      "id": 'deal-amount-deal-value',
      "placeholder": _vm.$t('DealValue'),
      "icon-right": "euroSign.svg"
    },
    model: {
      value: _vm.transaction.dealValue,
      callback: function ($$v) {
        _vm.$set(_vm.transaction, "dealValue", $$v);
      },
      expression: "transaction.dealValue"
    }
  })], 1), _c('v-row', {
    staticClass: "edit-brokerage-fee-row",
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'transaction-date',
      "placeholder": _vm.$t('TransactionDate'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.transaction.transactionDate,
      callback: function ($$v) {
        _vm.$set(_vm.transaction, "transactionDate", $$v);
      },
      expression: "transaction.transactionDate"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "transaction-details-edit edit-booking"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Booking")))]), _c('v-row', {
    staticClass: "edit-booking-date-row"
  }, [_c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'booking-date',
      "placeholder": _vm.$t('BookingDate'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.transaction.booking.date,
      callback: function ($$v) {
        _vm.$set(_vm.transaction.booking, "date", $$v);
      },
      expression: "transaction.booking.date"
    }
  }), _c('BaseInput', {
    attrs: {
      "type": "number",
      "id": 'deal-amount-booking-fee',
      "placeholder": _vm.$t('BookingFee'),
      "icon-right": "euroSign.svg"
    },
    model: {
      value: _vm.transaction.booking.fee,
      callback: function ($$v) {
        _vm.$set(_vm.transaction.booking, "fee", $$v);
      },
      expression: "transaction.booking.fee"
    }
  })], 1)], 1), _vm.transaction.transactionType === 'LeaseOut' ? _c('div', {
    staticClass: "transaction-details-edit edit-lease-agreement"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("LeaseAgreement")))]), _c('v-row', {
    staticClass: "edit-start-date-row"
  }, [_c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'lease-start-date',
      "placeholder": _vm.$t('StartDate'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.transaction.leaseAgreement.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.transaction.leaseAgreement, "startDate", $$v);
      },
      expression: "transaction.leaseAgreement.startDate"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'lease-end-date',
      "placeholder": _vm.$t('EndDate'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.transaction.leaseAgreement.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.transaction.leaseAgreement, "endDate", $$v);
      },
      expression: "transaction.leaseAgreement.endDate"
    }
  })], 1), _c('p', {
    staticClass: "content-small-semibold reminder-section"
  }, [_vm._v(" " + _vm._s(_vm.$t("ContractEndReminderDate")) + " ")]), _c('v-row', {
    staticClass: "edit-reminder-date-row"
  }, [_c('BaseDropdown', {
    key: _vm.updateReminderDate,
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'lease-start-date',
      "placeholder": _vm.$t('ReminderDate'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.transaction.leaseAgreement.reminderDate,
      callback: function ($$v) {
        _vm.$set(_vm.transaction.leaseAgreement, "reminderDate", $$v);
      },
      expression: "transaction.leaseAgreement.reminderDate"
    }
  })], 1)], 1) : _vm._e(), _vm.transaction.transactionType !== 'LeaseOut' ? _c('div', {
    staticClass: "transaction-details-edit edit-contract"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("ContractLawOfObligations")))]), _c('v-row', {
    staticClass: "edit-contract-row"
  }, [_c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'contract-obligations-date',
      "placeholder": _vm.$t('ContractDate'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.transaction.obligationsContract.date,
      callback: function ($$v) {
        _vm.$set(_vm.transaction.obligationsContract, "date", $$v);
      },
      expression: "transaction.obligationsContract.date"
    }
  }), _c('BaseInput', {
    attrs: {
      "type": "number",
      "id": 'contract-obligations-fee',
      "placeholder": _vm.$t('ContractFee'),
      "icon-right": "euroSign.svg"
    },
    model: {
      value: _vm.transaction.obligationsContract.fee,
      callback: function ($$v) {
        _vm.$set(_vm.transaction.obligationsContract, "fee", $$v);
      },
      expression: "transaction.obligationsContract.fee"
    }
  })], 1)], 1) : _vm._e(), _vm.transaction.transactionType !== 'LeaseOut' ? _c('div', {
    staticClass: "transaction-details-edit edit-contract"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("ContractLawOfProperty")))]), _c('v-row', {
    staticClass: "edit-contract-row"
  }, [_c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'contract-property-date',
      "placeholder": _vm.$t('ContractDate'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.transaction.propertyContract.date,
      callback: function ($$v) {
        _vm.$set(_vm.transaction.propertyContract, "date", $$v);
      },
      expression: "transaction.propertyContract.date"
    }
  }), _c('BaseInput', {
    attrs: {
      "type": "number",
      "id": 'contract-property-fee',
      "placeholder": _vm.$t('ContractFee'),
      "icon-right": "euroSign.svg"
    },
    model: {
      value: _vm.transaction.propertyContract.fee,
      callback: function ($$v) {
        _vm.$set(_vm.transaction.propertyContract, "fee", $$v);
      },
      expression: "transaction.propertyContract.fee"
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "transaction-details-edit edit-delivery-date"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("DateOfDeliveryOfPremises")))]), _c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'contract-property-date',
      "placeholder": _vm.$t('DateOfDelivery'),
      "show-dropdown-arrow": false,
      "type": "date"
    },
    model: {
      value: _vm.transaction.premiseDeliveryDate,
      callback: function ($$v) {
        _vm.$set(_vm.transaction, "premiseDeliveryDate", $$v);
      },
      expression: "transaction.premiseDeliveryDate"
    }
  })], 1), _c('div', {
    key: _vm.updateSeller,
    staticClass: "transaction-details-edit edit-seller"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.transaction.transactionType !== "LeaseOut" ? _vm.$t("Seller") : _vm.$t("Landlord")) + " ")]), _vm._l(_vm.transaction.seller, function (seller, sellerIndex) {
    return _c('div', {
      key: sellerIndex,
      staticClass: "edit-contract-party-section"
    }, [_c('v-row', {
      staticClass: "edit-contract-party-title"
    }, [_c('v-radio-group', {
      attrs: {
        "row": ""
      },
      model: {
        value: seller.type,
        callback: function ($$v) {
          _vm.$set(seller, "type", $$v);
        },
        expression: "seller.type"
      }
    }, _vm._l(_vm.clientTypes, function (item) {
      return _c('v-radio', {
        key: 'radio' + item._id,
        staticClass: "content",
        attrs: {
          "label": item.title,
          "color": "orange darken-3",
          "value": item.value,
          "row": ""
        },
        on: {
          "change": function ($event) {
            return _vm.radioChanged(sellerIndex, item.value, 'seller');
          }
        }
      });
    }), 1), sellerIndex !== 0 ? _c('div', {
      staticClass: "content-small clear-button",
      on: {
        "click": function ($event) {
          return _vm.removeClient(sellerIndex, 'seller');
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("../../assets/images/close.svg"),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm.$t("Remove").toLowerCase()) + " ")]) : _vm._e()], 1), seller.type === 'private' ? _c('div', {
      staticClass: "edit-contract-party-info"
    }, [_c('ClientListSearch', {
      attrs: {
        "icon-left": 'search20.svg',
        "placeholder": _vm.$t('FullName')
      },
      on: {
        "itemChosen": function ($event) {
          return _vm.selectPerson(sellerIndex, $event, 'seller');
        },
        "addNew": function ($event) {
          return _vm.openClientAddModal(sellerIndex, 'seller');
        },
        "removeValue": function ($event) {
          return _vm.removeFieldInput(sellerIndex, 'seller');
        }
      },
      model: {
        value: seller.name,
        callback: function ($$v) {
          _vm.$set(seller, "name", $$v);
        },
        expression: "seller.name"
      }
    }), _c('BaseInput', {
      attrs: {
        "id": 'contract-seller-personalcode',
        "placeholder": _vm.$t('PersonalCode')
      },
      model: {
        value: seller.personalCode,
        callback: function ($$v) {
          _vm.$set(seller, "personalCode", $$v);
        },
        expression: "seller.personalCode"
      }
    }), _c('BaseInput', {
      attrs: {
        "id": 'contract-seller-address',
        "placeholder": _vm.$t('Address')
      },
      model: {
        value: seller.address,
        callback: function ($$v) {
          _vm.$set(seller, "address", $$v);
        },
        expression: "seller.address"
      }
    })], 1) : _vm._e(), seller.type === 'company' ? _c('div', {
      staticClass: "edit-contract-party-info"
    }, [_c('BaseInput', {
      attrs: {
        "id": 'contract-seller-name',
        "placeholder": _vm.$t('FullName')
      },
      model: {
        value: seller.companyName,
        callback: function ($$v) {
          _vm.$set(seller, "companyName", $$v);
        },
        expression: "seller.companyName"
      }
    }), _c('BaseInput', {
      attrs: {
        "id": 'contract-seller-code',
        "placeholder": _vm.$t('RegistryCode')
      },
      model: {
        value: seller.registryCode,
        callback: function ($$v) {
          _vm.$set(seller, "registryCode", $$v);
        },
        expression: "seller.registryCode"
      }
    }), _c('BaseInput', {
      attrs: {
        "id": 'contract-seller-address',
        "placeholder": _vm.$t('Address')
      },
      model: {
        value: seller.companyAddress,
        callback: function ($$v) {
          _vm.$set(seller, "companyAddress", $$v);
        },
        expression: "seller.companyAddress"
      }
    }), _c('BaseInput', {
      attrs: {
        "id": 'contract-seller-signer',
        "placeholder": _vm.$t('Signatory')
      },
      model: {
        value: seller.signatory,
        callback: function ($$v) {
          _vm.$set(seller, "signatory", $$v);
        },
        expression: "seller.signatory"
      }
    })], 1) : _vm._e()], 1);
  }), _c('div', {
    staticClass: "link-small",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.addAnotherClient('seller');
      }
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t("AddAnother")) + " ")])], 2), _c('div', {
    staticClass: "transaction-details-edit edit-seller"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.transaction.transactionType !== "LeaseOut" ? _vm.$t("Buyer") : _vm.$t("Tenant")) + " ")]), _vm._l(_vm.transaction.buyer, function (buyer, buyerIndex) {
    return _c('div', {
      key: 'buyer' + buyer._id,
      staticClass: "edit-contract-party-section"
    }, [buyerIndex !== 0 ? _c('div', {
      staticClass: "object-top-header"
    }, [_c('div', {
      staticClass: "content-small clear-button",
      on: {
        "click": function ($event) {
          return _vm.removeClient(buyerIndex, 'buyer');
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("../../assets/images/close.svg"),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm.$t("Remove").toLowerCase()) + " ")])]) : _vm._e(), _c('v-radio-group', {
      attrs: {
        "row": ""
      },
      model: {
        value: buyer.type,
        callback: function ($$v) {
          _vm.$set(buyer, "type", $$v);
        },
        expression: "buyer.type"
      }
    }, _vm._l(_vm.clientTypes, function (item) {
      return _c('v-radio', {
        key: 'radio' + item._id,
        staticClass: "content",
        attrs: {
          "label": item.title,
          "color": "orange darken-3",
          "value": item.value,
          "row": ""
        },
        on: {
          "change": function ($event) {
            return _vm.radioChanged(buyerIndex, item.value, 'buyer');
          }
        }
      });
    }), 1), buyer.type === 'private' ? _c('div', {
      staticClass: "edit-contract-party-info"
    }, [_c('ClientListSearch', {
      attrs: {
        "icon-left": 'search20.svg',
        "placeholder": _vm.$t('FullName')
      },
      on: {
        "itemChosen": function ($event) {
          return _vm.selectPerson(buyerIndex, $event, 'buyer');
        },
        "addNew": function ($event) {
          return _vm.openClientAddModal(buyerIndex, 'buyer');
        },
        "removeValue": function ($event) {
          return _vm.removeFieldInput(buyerIndex, 'buyer');
        }
      },
      model: {
        value: buyer.name,
        callback: function ($$v) {
          _vm.$set(buyer, "name", $$v);
        },
        expression: "buyer.name"
      }
    }), _c('BaseInput', {
      attrs: {
        "id": 'contract-buyer-personalcode',
        "placeholder": _vm.$t('PersonalCode')
      },
      model: {
        value: buyer.personalCode,
        callback: function ($$v) {
          _vm.$set(buyer, "personalCode", $$v);
        },
        expression: "buyer.personalCode"
      }
    }), _c('BaseInput', {
      attrs: {
        "id": 'contract-buyer-address',
        "placeholder": _vm.$t('Address')
      },
      model: {
        value: buyer.address,
        callback: function ($$v) {
          _vm.$set(buyer, "address", $$v);
        },
        expression: "buyer.address"
      }
    })], 1) : _vm._e(), buyer.type === 'company' ? _c('div', {
      staticClass: "edit-contract-party-info"
    }, [_c('BaseInput', {
      attrs: {
        "id": 'contract-buyer-name',
        "placeholder": _vm.$t('FullName')
      },
      model: {
        value: buyer.companyName,
        callback: function ($$v) {
          _vm.$set(buyer, "companyName", $$v);
        },
        expression: "buyer.companyName"
      }
    }), _c('BaseInput', {
      attrs: {
        "id": 'contract-buyer-code',
        "placeholder": _vm.$t('RegistryCode')
      },
      model: {
        value: buyer.registryCode,
        callback: function ($$v) {
          _vm.$set(buyer, "registryCode", $$v);
        },
        expression: "buyer.registryCode"
      }
    }), _c('BaseInput', {
      attrs: {
        "id": 'contract-buyer-address',
        "placeholder": _vm.$t('Address')
      },
      model: {
        value: buyer.companyAddress,
        callback: function ($$v) {
          _vm.$set(buyer, "companyAddress", $$v);
        },
        expression: "buyer.companyAddress"
      }
    }), _c('BaseInput', {
      attrs: {
        "id": 'contract-buyer-signer',
        "placeholder": _vm.$t('Signatory')
      },
      model: {
        value: buyer.signatory,
        callback: function ($$v) {
          _vm.$set(buyer, "signatory", $$v);
        },
        expression: "buyer.signatory"
      }
    })], 1) : _vm._e()], 1);
  }), _c('div', {
    staticClass: "link-small",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.addAnotherClient('buyer');
      }
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t("AddAnother")) + " ")])], 2), _c('div', {
    staticClass: "transaction-details-edit edit-comment"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Comment")))]), _c('div', {
    staticClass: "comment-text-wrapper"
  }, [_c('BaseTextarea', {
    staticStyle: {
      "width": "536px"
    },
    attrs: {
      "id": 'contract-comment'
    },
    model: {
      value: _vm.transaction.comment,
      callback: function ($$v) {
        _vm.$set(_vm.transaction, "comment", $$v);
      },
      expression: "transaction.comment"
    }
  })], 1)]), _c('div', {
    staticClass: "sticky-footer-background"
  }), _vm.isEdit ? _c('div', {
    staticClass: "sticky-footer"
  }, [_c('div', {
    staticClass: "sticky-footer-row"
  }, [_c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.editTransaction();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _vm.isEdit ? _c('SubmitButton', {
    attrs: {
      "id": "save-edit-project-button"
    },
    on: {
      "clicked": function ($event) {
        return _vm.submitTransaction();
      }
    }
  }) : _vm._e()], 1)]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.clientAddModalOpen ? _c('AddModal', {
    staticStyle: {
      "z-index": "120"
    },
    attrs: {
      "accordion": true,
      "not-contact-view-route": true
    },
    on: {
      "newAddedContact": _vm.chooseNewClientFromModal,
      "closed": _vm.closeClientAddModal
    }
  }) : _vm._e(), _c('TransactionPrintView', {
    staticStyle: {
      "position": "absolute",
      "right": "-1000px"
    },
    attrs: {
      "transaction": _vm.transaction,
      "print": _vm.printUpdater
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }