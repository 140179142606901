<template>
  <div id="transaction-overlay" :style="OverlayStyle">
    <div
        class="transaction-panel"
        v-if="transaction"
        v-click-outside="($event) => closeModal($event)"
        :key="panelUpdater"
    >
      <div class="sticky-header">
        <div class="modal-header">
          <v-row class="header-first-row">
            <div class="transaction-name-row">

            <h4 class="modal-title">{{ $t("TransactionDetails") }}</h4>
             <DealTypeContainer
              v-if="transaction"
              :small-font="false"
              :deal-type="transaction.transactionType"
            />
            </div>
            <div>
              <div class="header-button-container">
                <div @click="printUpdater++" class="header-button">
                  <img src="../../assets/images/print.svg" alt="" />
                  <p class="header-button-text">{{ $t("Print") }}</p>
                </div>
                <div
                    class="header-button"
                    v-if="!projectArchived && hasAccess(transaction)"
                    @click="editTransaction"
                >
                  <img src="../../assets/images/edit.svg" alt="" />
                  <p class="header-button-text">{{ $t("Edit") }}</p>
                </div>
              </div>
            </div>
          </v-row>
          <v-row class="header-second-row">
            <div class="project-info">
              <img src="../../assets/images/projects.svg" alt="" />
              <a
                :href="transaction.isUnit ? path + '/property-development/' + developmentId + '/general' : path + '/projects/' + transaction.projectId + '/general'"
                @keyup.enter="openProject(transaction.projectId)"
                class="button-small hoverable-info"
                style="text-decoration: none"
              >
                {{ transaction.transactionName }}
              </a>
              <div
                class="open-new-tab-button"
                @click="openProjectNewTab(transaction.projectId)"
              >
                <img
                  style="width: 16px; height: 16px"
                  src="../../assets/images/open_new_tab.svg"
                  alt=""
                />
                <span>{{ $t("OpenInNewTab") }}</span>
              </div>
            </div>
          </v-row>
        </div>
        <div class="main-info" v-if="!isEdit">
          <v-row class="listing-field-row">
            <p class="field-name">{{ $t("DealValue") }}</p>
            <p class="field-value">
              {{
                transaction.dealValue
                    ? $nMapper.price(transaction.dealValue, "€")
                    : "-"
              }}
            </p>
          </v-row>
          <v-row class="listing-field-row">
            <p class="field-name">{{ $t("BrokerageFee") }}</p>
            <p class="field-value">
              {{ brokerFee() }}
            </p>
          </v-row>
          <v-row class="listing-field-row">
            <p class="field-name">{{ $t("DateOfTransaction") }}</p>
            <p class="field-value">
              {{ formatDate(transaction.transactionDate) }}
            </p>
          </v-row>
          <v-row v-if="user.access.company._id !== '5p1EOo4yqjeiYCN3R16w'" class="listing-field-row">
            <p class="field-name">{{ $t("Status") }}</p>
            <div
                v-if="transaction.status"
                @click="openStatusDropdown(transaction)"
                :id="'statusLabelSidepanel' + transaction._id"
                class="statusLabelSidepanel content-bold"
                v-bind:class="{
                'paid-label ': transaction.status === 'paid',
                'not-paid-label ': transaction.status === 'not_paid',
              }"
            >
              {{ formatStatus(transaction.status) }}
            </div>
          </v-row>
          <v-row class="listing-field-row">
            <p class="field-name">{{ $t("BookingDateAndFee") }}</p>
            <p class="field-value">
              {{
                `${
                    transaction.booking
                        ? formatDate(transaction.booking.date)
                        : "-"
                } / ${
                    transaction.booking && transaction.booking.fee
                        ? transaction.booking.fee + " €"
                        : "-"
                }`
              }}
            </p>
          </v-row>
          <v-row
              v-if="transaction.transactionType === 'LeaseOut'"
              class="listing-field-row"
          >
            <p class="field-name">{{ $t("ValidityOfLeaseAgreement") }}</p>
            <p class="field-value">
              {{
                `${
                    transaction.leaseAgreement &&
                    transaction.leaseAgreement.startDate
                        ? formatDate(transaction.leaseAgreement.startDate)
                        : "-"
                } - ${
                    transaction.leaseAgreement &&
                    transaction.leaseAgreement.endDate
                        ? formatDate(transaction.leaseAgreement.endDate)
                        : "-"
                }`
              }}
            </p>
          </v-row>
          <v-row
              v-if="
              transaction.transactionType === 'LeaseOut' &&
              transaction.leaseAgreement &&
              transaction.leaseAgreement.reminderDate
            "
              class="listing-field-row"
          >
            <p class="field-name">{{ $t("ContractEndReminderDate") }}</p>
            <p class="field-value">
              {{ formatDate(transaction.leaseAgreement.reminderDate) }}
            </p>
          </v-row>
          <v-row
              v-if="transaction.transactionType !== 'LeaseOut'"
              class="listing-field-row"
          >
            <p class="field-name">{{ $t("ObligationsDateAndFee") }}</p>
            <p class="field-value">
              {{
                `${
                    transaction.obligationsContract
                        ? formatDate(transaction.obligationsContract.date)
                        : "-"
                } / ${
                    transaction.obligationsContract &&
                    transaction.obligationsContract.fee
                        ? transaction.obligationsContract.fee + " €"
                        : "-"
                }`
              }}
            </p>
          </v-row>
          <v-row
              v-if="transaction.transactionType !== 'LeaseOut'"
              class="listing-field-row"
          >
            <p class="field-name">{{ $t("PropertyDateAndFee") }}</p>
            <p class="field-value">
              {{
                `${
                    transaction.propertyContract
                        ? formatDate(transaction.propertyContract.date)
                        : "-"
                } / ${
                    transaction.propertyContract &&
                    transaction.propertyContract.fee
                        ? transaction.propertyContract.fee + " €"
                        : "-"
                }`
              }}
            </p>
          </v-row>
          <v-row class="listing-field-row">
            <p class="field-name">{{ $t("PremiseDeliveryDate") }}</p>
            <p class="field-value">
              {{ formatDate(transaction.premiseDeliveryDate) }}
            </p>
          </v-row>
          <v-row
              v-if="transaction.creator.userId === user._id || user.isAdmin"
              class="listing-field-row"
          >
            <p class="field-name">
              {{
                transaction.transactionType !== "LeaseOut"
                    ? $t("Seller")
                    : $t("Landlord")
              }}
            </p>
            <div>
              <div
                  v-for="seller in transaction.seller"
                  :key="seller._id"
                  style="margin-bottom: 12px"
              >
                <div v-if="seller.type === 'private'">
                  <p class="field-value" style="font-weight: 600">
                    {{ seller.name }}
                  </p>
                  <p class="field-value">
                    {{ seller.personalCode }}
                  </p>
                  <p class="field-value">
                    {{ seller.address }}
                  </p>
                </div>
                <div v-if="seller.type === 'company'">
                  <p class="field-value" style="font-weight: 600">
                    {{ seller.companyName }}
                  </p>
                  <p class="field-value">
                    {{ seller.registryCode }}
                  </p>
                  <p class="field-value">
                    {{ seller.companyAddress }}
                  </p>
                  <p class="field-value">
                    {{ seller.signatory }}
                  </p>
                </div>
              </div>
            </div>
          </v-row>
          <v-row
              v-if="transaction.creator.userId === user._id || user.isAdmin"
              class="listing-field-row"
          >
            <p class="field-name">
              {{
                transaction.transactionType !== "LeaseOut"
                    ? $t("Buyer")
                    : $t("Tenant")
              }}
            </p>
            <div>
              <div v-for="buyer in transaction.buyer" :key="buyer._id">
                <div v-if="buyer.type === 'private'">
                  <p class="field-value" style="font-weight: 600">
                    {{ buyer.name }}
                  </p>
                  <p class="field-value">
                    {{ buyer.personalCode }}
                  </p>
                  <p class="field-value">
                    {{ buyer.address }}
                  </p>
                </div>
                <div v-if="buyer.type === 'company'">
                  <p class="field-value" style="font-weight: 600">
                    {{ buyer.companyName }}
                  </p>
                  <p class="field-value">
                    {{ buyer.registryCode }}
                  </p>
                  <p class="field-value">
                    {{ buyer.companyAddress }}
                  </p>
                  <p class="field-value">
                    {{ buyer.signatory }}
                  </p>
                </div>
              </div>
            </div>
          </v-row>
          <v-row class="comment-section">
            <div class="comment-container">
              <v-row>
                <img
                    src="@/assets/images/chat-no-bubble.svg"
                    style="width: 20px; height: 20px; margin-right: 12px"
                    alt=""
                />
                <div
                    @click="openEditComment"
                    v-if="!transaction.comment && !editComment"
                >
                  <p class="link">
                    +
                    {{
                      $t("addComment").charAt(0).toUpperCase() +
                      $t("addComment").slice(1)
                    }}
                  </p>
                </div>

                <textarea
                    id="textarea"
                    class="transaction-textarea content-normal"
                    rows="5"
                    v-if="editComment"
                    ref="commentbox"
                    v-model="transactionComment"
                    @focusout="closeEditComment"
                />
                <div
                    v-if="transaction.comment && !editComment"
                    @click="openEditComment"
                    style="display: flex; flex-direction: column"
                >
                  <div class="transaction-comment-header label12 gray-text">
                    <div>{{ $t("Comment") }}</div>
                  </div>
                  <div class="comment-text content-normal">
                    {{ transaction.comment }}
                  </div>
                </div>
              </v-row>
            </div>
          </v-row>
        </div>
      </div>
      <!--        -->
      <div v-if="isEdit" class="transaction-details-edit-container">
        <div class="transaction-details-edit edit-brokerage-fee">
          <h5>{{ $t("BrokerageFee") }}</h5>

          <div v-if="user.access.company._id !== '5p1EOo4yqjeiYCN3R16w'" class="edit-status-subcontainer">
            <div class="content-small content semi-bold">
              {{ $t("Status") }}
            </div>
            <div
                class="edit-status-filter"
                @click="changeTransactionPaidStatus"
            >
              <div
                  id="status-not-paid"
                  class="status-filter-item"
                  :class="{
                  'filter-item-not-paid': !isTransactionPaid,
                }"
              >
                <p id="status-not-paid">
                  {{ transaction.isUnit ? $t("Incomplete") : $t("NotPaid") }}
                </p>
              </div>
              <div
                  id="status-paid"
                  class="status-filter-item"
                  :class="{
                  'filter-item-paid': isTransactionPaid,
                }"
              >
                <p id="status-paid">
                  {{ transaction.isUnit ? $t("Completed") : $t("Paid") }}
                </p>
              </div>
            </div>
          </div>
          <div>
            <v-row v-bind:class="{'margin-up': user.access.company._id !== '5p1EOo4yqjeiYCN3R16w'}">
              <div class="content-small content semi-bold">
                {{ $t("BrokerageFeeIs") }}
              </div>
            </v-row>
            <v-radio-group
                style="padding-top: 12px"
                v-model="transaction.brokerageFeeType"
                row
            >
              <v-radio
                  color="#FF5C01"
                  value="percentOfPrice"
                  class="radio-button"
                  :label="$t('PercentageOfFinalPrice')"
              ></v-radio>
              <v-radio
                  color="#FF5C01"
                  value="agreedPrice"
                  class="radio-button"
                  :label="$t('AgreedAmount')"
              ></v-radio>
            </v-radio-group>
            <div
                v-if="transaction.brokerageFeeType === 'agreedPrice'"
                style="display: contents"
                class="edit-brokerage-fee-row"
            >
              <v-row style="gap: 12px; display: flex; width: 100%">
                <BaseInput
                    :id="'brokerage-fee-field'"
                    :placeholder="$t('BrokerageFee')"
                    icon-right="euroSign.svg"
                    type="number"
                    :format="'price'"
                    v-model="transaction.brokerageFee"
                />
              </v-row>
            </div>
            <v-row
                v-else-if="transaction.brokerageFeeType === 'percentOfPrice'"
                class="edit-brokerage-fee-row"
            >
              <BaseInput
                  type="number"
                  :format="'price'"
                  :id="'deal-amount-percent'"
                  icon-right="percentage.svg"
                  :placeholder="$t('Percentage')"
                  v-model="transaction.brokerFeePercentage"
              />
              <BaseInput
                  type="number"
                  :format="'price'"
                  :id="'deal-amount-min-fee'"
                  :placeholder="$t('MinimumFee')"
                  icon-right="euroSign.svg"
                  v-model="transaction.minimumFee"
              />
            </v-row>

            <v-row style="margin-top: 12px" class="edit-brokerage-fee-row">
              <BaseInput
                  type="number"
                  :format="'price'"
                  :id="'deal-amount-deal-value'"
                  :placeholder="$t('DealValue')"
                  icon-right="euroSign.svg"
                  v-model="transaction.dealValue"
              />
            </v-row>

            <v-row style="margin-top: 12px" class="edit-brokerage-fee-row">
              <BaseDropdown
                  icon-left="calendar_20_20.svg"
                  :id="'transaction-date'"
                  :placeholder="$t('TransactionDate')"
                  v-model="transaction.transactionDate"
                  :show-dropdown-arrow="true"
                  type="date"
              >
              </BaseDropdown>
            </v-row>
          </div>
        </div>

        <div class="transaction-details-edit edit-booking">
          <h5>{{ $t("Booking") }}</h5>
          <v-row class="edit-booking-date-row">
            <BaseDropdown
                icon-left="calendar_20_20.svg"
                :id="'booking-date'"
                :placeholder="$t('BookingDate')"
                v-model="transaction.booking.date"
                :show-dropdown-arrow="true"
                type="date"
            >
            </BaseDropdown>
            <BaseInput
                type="number"
                :id="'deal-amount-booking-fee'"
                :placeholder="$t('BookingFee')"
                icon-right="euroSign.svg"
                v-model="transaction.booking.fee"
            />
          </v-row>
        </div>
        <div
            v-if="transaction.transactionType === 'LeaseOut'"
            class="transaction-details-edit edit-lease-agreement"
        >
          <h5>{{ $t("LeaseAgreement") }}</h5>
          <v-row class="edit-start-date-row">
            <BaseDropdown
              icon-left="calendar_20_20.svg"
              :id="'lease-start-date'"
              :placeholder="$t('StartDate')"
              v-model="transaction.leaseAgreement.startDate"
              :show-dropdown-arrow="true"
              type="date"
            >
            </BaseDropdown>
            <BaseDropdown
                icon-left="calendar_20_20.svg"
                :id="'lease-end-date'"
                :placeholder="$t('EndDate')"
                v-model="transaction.leaseAgreement.endDate"
                :show-dropdown-arrow="true"
                type="date"
            >
            </BaseDropdown>
          </v-row>
          <p class="content-small-semibold reminder-section">
            {{ $t("ContractEndReminderDate") }}
          </p>
          <v-row class="edit-reminder-date-row">
            <BaseDropdown
              icon-left="calendar_20_20.svg"
              :id="'lease-start-date'"
              :placeholder="$t('ReminderDate')"
              :key="updateReminderDate"
              v-model="transaction.leaseAgreement.reminderDate"
              :show-dropdown-arrow="true"
              type="date"
            >
            </BaseDropdown>
          </v-row>
        </div>

        <div
            v-if="transaction.transactionType !== 'LeaseOut'"
            class="transaction-details-edit edit-contract"
        >
          <h5>{{ $t("ContractLawOfObligations") }}</h5>
          <v-row class="edit-contract-row">
            <BaseDropdown
                icon-left="calendar_20_20.svg"
                :id="'contract-obligations-date'"
                :placeholder="$t('ContractDate')"
                v-model="transaction.obligationsContract.date"
                :show-dropdown-arrow="true"
                type="date"
            >
            </BaseDropdown>
            <BaseInput
                type="number"
                :id="'contract-obligations-fee'"
                :placeholder="$t('ContractFee')"
                icon-right="euroSign.svg"
                v-model="transaction.obligationsContract.fee"
            />
          </v-row>
        </div>

        <div
            v-if="transaction.transactionType !== 'LeaseOut'"
            class="transaction-details-edit edit-contract"
        >
          <h5>{{ $t("ContractLawOfProperty") }}</h5>
          <v-row class="edit-contract-row">
            <BaseDropdown
                icon-left="calendar_20_20.svg"
                :id="'contract-property-date'"
                :placeholder="$t('ContractDate')"
                v-model="transaction.propertyContract.date"
                :show-dropdown-arrow="true"
                type="date"
            >
            </BaseDropdown>
            <BaseInput
                type="number"
                :id="'contract-property-fee'"
                :placeholder="$t('ContractFee')"
                icon-right="euroSign.svg"
                v-model="transaction.propertyContract.fee"
            />
          </v-row>
        </div>

        <div class="transaction-details-edit edit-delivery-date">
          <h5>{{ $t("DateOfDeliveryOfPremises") }}</h5>
          <BaseDropdown
              icon-left="calendar_20_20.svg"
              :id="'contract-property-date'"
              :placeholder="$t('DateOfDelivery')"
              v-model="transaction.premiseDeliveryDate"
              :show-dropdown-arrow="false"
              type="date"
          >
          </BaseDropdown>
        </div>

        <div :key="updateSeller" class="transaction-details-edit edit-seller">
          <h5>
            {{
              transaction.transactionType !== "LeaseOut"
                  ? $t("Seller")
                  : $t("Landlord")
            }}
          </h5>
          <div
              v-for="(seller, sellerIndex) in transaction.seller"
              :key="sellerIndex"
              class="edit-contract-party-section"
          >
            <v-row class="edit-contract-party-title">
              <v-radio-group row v-model="seller.type">
                <v-radio
                    v-for="item in clientTypes"
                    :key="'radio' + item._id"
                    :label="item.title"
                    color="orange darken-3"
                    class="content"
                    :value="item.value"
                    @change="radioChanged(sellerIndex, item.value, 'seller')"
                    row
                >
                </v-radio>
              </v-radio-group>

              <div
                  v-if="sellerIndex !== 0"
                  class="content-small clear-button"
                  @click="removeClient(sellerIndex, 'seller')"
              >
                <img src="../../assets/images/close.svg" alt="" />
                {{ $t("Remove").toLowerCase() }}
              </div>
            </v-row>

            <div
                v-if="seller.type === 'private'"
                class="edit-contract-party-info"
            >
              <ClientListSearch
                  @itemChosen="
                  ($event) => selectPerson(sellerIndex, $event, 'seller')
                "
                  :icon-left="'search20.svg'"
                  :placeholder="$t('FullName')"
                  v-model="seller.name"
                  @addNew="openClientAddModal(sellerIndex, 'seller')"
                  @removeValue="removeFieldInput(sellerIndex, 'seller')"
              />
              <BaseInput
                  :id="'contract-seller-personalcode'"
                  :placeholder="$t('PersonalCode')"
                  v-model="seller.personalCode"
              />
              <BaseInput
                  :id="'contract-seller-address'"
                  :placeholder="$t('Address')"
                  v-model="seller.address"
              />
            </div>

            <div
                v-if="seller.type === 'company'"
                class="edit-contract-party-info"
            >
              <BaseInput
                  :id="'contract-seller-name'"
                  :placeholder="$t('FullName')"
                  v-model="seller.companyName"
              />
              <BaseInput
                  :id="'contract-seller-code'"
                  :placeholder="$t('RegistryCode')"
                  v-model="seller.registryCode"
              />
              <BaseInput
                  :id="'contract-seller-address'"
                  :placeholder="$t('Address')"
                  v-model="seller.companyAddress"
              />
              <BaseInput
                  :id="'contract-seller-signer'"
                  :placeholder="$t('Signatory')"
                  v-model="seller.signatory"
              />
            </div>
          </div>
          <div
              @click="addAnotherClient('seller')"
              class="link-small"
              style="cursor: pointer"
          >
            + {{ $t("AddAnother") }}
          </div>
        </div>

        <div class="transaction-details-edit edit-seller">
          <h5>
            {{
              transaction.transactionType !== "LeaseOut"
                  ? $t("Buyer")
                  : $t("Tenant")
            }}
          </h5>
          <div
              v-for="(buyer, buyerIndex) in transaction.buyer"
              :key="'buyer' + buyer._id"
              class="edit-contract-party-section"
          >
            <div class="object-top-header" v-if="buyerIndex !== 0">
              <div
                  class="content-small clear-button"
                  @click="removeClient(buyerIndex, 'buyer')"
              >
                <img src="../../assets/images/close.svg" alt="" />
                {{ $t("Remove").toLowerCase() }}
              </div>
            </div>

            <v-radio-group row v-model="buyer.type">
              <v-radio
                  v-for="item in clientTypes"
                  :key="'radio' + item._id"
                  :label="item.title"
                  color="orange darken-3"
                  class="content"
                  :value="item.value"
                  @change="radioChanged(buyerIndex, item.value, 'buyer')"
                  row
              >
              </v-radio>
            </v-radio-group>
            <div
                v-if="buyer.type === 'private'"
                class="edit-contract-party-info"
            >
              <ClientListSearch
                  @itemChosen="
                  ($event) => selectPerson(buyerIndex, $event, 'buyer')
                "
                  :icon-left="'search20.svg'"
                  :placeholder="$t('FullName')"
                  v-model="buyer.name"
                  @addNew="openClientAddModal(buyerIndex, 'buyer')"
                  @removeValue="removeFieldInput(buyerIndex, 'buyer')"
              />
              <BaseInput
                  :id="'contract-buyer-personalcode'"
                  :placeholder="$t('PersonalCode')"
                  v-model="buyer.personalCode"
              />
              <BaseInput
                  :id="'contract-buyer-address'"
                  :placeholder="$t('Address')"
                  v-model="buyer.address"
              />
            </div>

            <div
                v-if="buyer.type === 'company'"
                class="edit-contract-party-info"
            >
              <BaseInput
                  :id="'contract-buyer-name'"
                  :placeholder="$t('FullName')"
                  v-model="buyer.companyName"
              />
              <BaseInput
                  :id="'contract-buyer-code'"
                  :placeholder="$t('RegistryCode')"
                  v-model="buyer.registryCode"
              />
              <BaseInput
                  :id="'contract-buyer-address'"
                  :placeholder="$t('Address')"
                  v-model="buyer.companyAddress"
              />
              <BaseInput
                  :id="'contract-buyer-signer'"
                  :placeholder="$t('Signatory')"
                  v-model="buyer.signatory"
              />
            </div>
          </div>
          <div
              @click="addAnotherClient('buyer')"
              class="link-small"
              style="cursor: pointer"
          >
            + {{ $t("AddAnother") }}
          </div>
        </div>

        <div class="transaction-details-edit edit-comment">
          <h5>{{ $t("Comment") }}</h5>
          <div class="comment-text-wrapper">
            <BaseTextarea
                :id="'contract-comment'"
                style="width: 536px"
                v-model="transaction.comment"
            />
          </div>
        </div>

        <div class="sticky-footer-background"></div>

        <div v-if="isEdit" class="sticky-footer">
          <div class="sticky-footer-row">
            <button @click="editTransaction()" class="cancel">
              {{ $t("Cancel") }}
            </button>
            <SubmitButton
                id="save-edit-project-button"
                v-if="isEdit"
                @clicked="submitTransaction()"
            />
          </div>
        </div>
      </div>
    </div>
    <AddModal
        style="z-index: 120"
        @newAddedContact="chooseNewClientFromModal"
        @closed="closeClientAddModal"
        :accordion="true"
        :not-contact-view-route="true"
        v-if="clientAddModalOpen"
    >
    </AddModal>
    <TransactionPrintView
        style="position: absolute; right: -1000px"
        :transaction="transaction"
        :print="printUpdater"
    />
  </div>
</template>

<script>
import DealTypeContainer from "@/components/common/DealTypeContainer";
import BaseInput from "../common/BaseInput";
import { mapGetters } from "vuex";
import BaseDropdown from "../common/BaseDropdown";
import nanoid from "nanoid";
import ClientListSearch from "../common/ClientListSearch";
import axios from "axios";
import SubmitButton from "../common/SubmitButton";
import AddModal from "../Kliendiregister/AddModal";
import moment from "moment";
import TransactionPrintView from "@/components/Transactions/TransactionPrintView";
import BaseTextarea from "@/components/common/BaseTextarea.vue";
export default {
  name: "TransactionsDetail",
  props: ["transactionId", "transactionProjectId", "updateDetailPanel"],
  components: {
    BaseTextarea,
    TransactionPrintView,
    AddModal,
    SubmitButton,
    ClientListSearch,
    DealTypeContainer,
    BaseInput,
    BaseDropdown,
  },
  data() {
    return {
      transaction: null,
      statusesOpen: false,
      position: {},
      selectedTransaction: null,
      projectArchived: false,
      isEdit: false,
      formUpdater: 0,
      panelUpdater: 0,
      updateSeller: 0,
      radioChangedValue: 0,
      updateReminderDate: 0,
      clientAddModalOpen: false,
      selectedbuyerOrSeller: null,
      contact: null,
      path: null,
      developmentId: null,
      selectedIndex: null,
      OverlayStyle: {
        background: "#0000001a",
      },
      clientTypes: [
        {
          _id: nanoid(8),
          value: "private",
          title: this.$t("PrivatePerson.Label"),
        },
        {
          _id: nanoid(8),
          value: "company",
          title: this.$t("Company"),
        },
      ],
      showErrors: false,
      errors: null,
      validatePrivate: ["name", "personalCode", "address"],
      validateCompany: [
        "companyName",
        "registryCode",
        "companyAddress",
        "signatory",
      ],
      printUpdater: 0,
      editComment: false,
      transactionComment: "",
    };
  },
  created() {
    this.getTransaction();
    this.path = window.location.origin;
  },
  mounted() {},
  watch: {
    radioChangedValue: async function () {
      await this.fetchSellerData();
    },
    updateDetailPanel: async function () {
      await this.getTransaction();
    },
    "transaction.leaseAgreement.endDate": function () {
      if (
          this.transaction.leaseAgreement.endDate &&
          !this.transaction.leaseAgreement.reminderDate
      ) {
        this.transaction.leaseAgreement.reminderDate = moment(
            this.transaction.leaseAgreement.endDate
        )
            .subtract(30, "days")
            .format("YYYY-MM-DD");
        this.updateReminderDate++;
      }
    },
  },
  computed: {
    isTransactionPaid() {
      return this.transaction.status === "paid";
    },
    ...mapGetters(["user"]),
  },
  methods: {
    brokerFee() {
      if (this.transaction.brokerageFee) {
        return `${this.transaction.brokerageFee} €`;
      } else if (this.transaction.brokerFeePercentage) {
        return `${this.transaction.brokerFeePercentage}%`;
      } else if (this.transaction.minimumFee) {
        return `${this.transaction.minimumFee} €`;
      } else {
        return "-";
      }
    },
    formatStatus(status) {
      if (status === "not_paid") {
        if (this.transaction.isUnit) {
          return this.$t("Incomplete");
        }
        return this.$t("NotPaid");
      } else if (status === "paid") {
        if (this.transaction.isUnit) {
          return this.$t("Completed");
        }
        return this.$t("Paid");
      }
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("DD.MM.YYYY");
      } else {
        return "-";
      }
    },
    /*  formatBrokerageFee(object) {
       if (object.brokerageFeeType === "percentOfPrice") {
         if (!object.dealValue) {
           if (object.minimumFee) {
             this.transaction.brokerageFee = object.minimumFee;
             return object.minimumFee + " €";
           } else {
             this.transaction.brokerageFee = null;
             return "-";
           }
         } else {
           let percent = parseFloat(object.brokerFeePercentage) / 100;
           let answer = parseFloat(object.dealValue) * percent;
           if (object.minimumFee) {
             if (answer < parseFloat(object.minimumFee)) {
               answer = object.minimumFee;
             }
           }
           if (Number.isSafeInteger(parseFloat(answer))) {
             return parseInt(answer) + " €";
           } else {
             return parseFloat(answer).toFixed(2) + " €";
           }
         }
       } else if (object.brokerageFeeType === "agreedPrice") {
         if (object.brokerageFee) {
           if (Number.isSafeInteger(parseFloat(object.brokerageFee))) {
             return parseInt(object.brokerageFee) + " €";
           } else {
             return parseFloat(object.brokerageFee).toFixed(2) + " €";
           }
         } else {
           this.transaction.brokerageFee = null;

           return "-";
         }
       }
     }, */
    async getTransaction() {
      if (this.transactionId) {
        const response = await axios.get(
            `/api/transaction/${this.transactionId}`
        );
        this.transaction = response.data.transaction;
        if (this.transaction.comment) {
          this.transactionComment = this.transaction.comment;
        }
        if (this.transaction.pipelineStep === "archived") {
          this.projectArchived = true;
        }
      } else if (this.transactionProjectId) {
        const response = await axios.get(
            `/api/transaction/project/${this.transactionProjectId}`
        );
        if (
            response.data.transaction &&
            response.data.transaction.pipelineStep === "archived"
        ) {
          this.projectArchived = true;
        }
        this.transaction = response.data.transaction;
      }
      const nestedObject = [
        "buyer",
        "booking",
        "obligationsContract",
        "propertyContract",
        "leaseAgreement",
      ];
      for (let object of nestedObject) {
        if (this.transaction && !this.transaction[object]) {
          if (object === "buyer") {
            this.transaction[object] = [];
            this.transaction[object].push({ type: "private", name: "" });
          } else {
            this.transaction[object] = {};
          }
        }
      }
      if (this.transaction && this.transaction.isUnit) {
        const unitResponse = await axios.get(`/api/unit/name/${this.transaction.projectId}`);
        this.developmentId = unitResponse.data._id
      }
    },
    closeModal(event) {
      if (
          event.target.id !== "transaction-overlay" &&
          !event.target.classList.contains("cancel")
      )
        return;
      this.$emit("closePanel");
    },
    async editTransaction() {
      this.isEdit = !this.isEdit;
    },
    async getContact(customerId) {
      const response = await axios.get(`/api/contact/${customerId}`);
      this.contact = response.data;
    },
    async fetchSellerData() {
      if (this.transaction.seller[0].type === "private") {
        this.transaction.seller[0].customerId = this.contact.customerId;
        this.transaction.seller[0].name = this.transaction.broker.contactName;
        this.transaction.seller[0].email = this.contact.email;
        await this.getContact(this.transaction.broker.contactId);
        this.transaction.seller[0].personalCode = this.contact.personalCode;
        this.transaction.seller[0].address = this.contact.address;
      } else {
        this.transaction.seller[0].companyName =
            this.transaction.broker.company_name;
      }
      this.updateSeller++;
    },
    async openProject(projectId) {
      const isUnit = this.transaction.isUnit;

      let response;
      isUnit
        ? (response = await axios.get(`/api/unit/access/${projectId}`))
        : (response = await axios.get(`/api/project/access/${projectId}`));

      if (!response || !response.data || !response.data.access) {
        this.toastError(this.$t("noProjectAccess"));
        return;
      }
      if (isUnit) {
        this.$router
            .push({
              name: "DevelopmentGeneral",
            params: { id: this.developmentId },
            })
            .then(() => {
            });
      } else {
        this.$router
            .push({
              name: "DealDetail",
              params: {id: projectId},
            })
            .then(() => {
            });
      }
    },
    async openProjectNewTab(projectId) {
      const isUnit = this.transaction.isUnit;
      if (isUnit) {
       /*  const unitResponse = await axios.get(`/api/unit/name/${projectId}`);
        const developmentId = unitResponse.data._id; */
        const routeData = this.$router.resolve({name: 'DevelopmentGeneral', params: {id: this.developmentId}});
        window.open(routeData.href, '_blank');
      } else {
        const routeData = this.$router.resolve({
          name: "DealDetail",
          params: { id: projectId },
        });
        window.open(routeData.href, "_blank");
      }
    },
    async submitTransaction() {
      // if (!this.isFormValid()) {
      //   console.log('errors!')
      //   this.showErrors = true;
      //   return;
      // }
      const updatedTransaction = this.transaction;
      updatedTransaction.paidDate =
          updatedTransaction.status === "paid" ? moment().format() : null;
      await this.updateTransaction(updatedTransaction);
      this.isEdit = false;
    },

    async updateTransaction(updatedTransaction) {
      console.log("emitting 1");
      this.$emit("updateTransaction", updatedTransaction);
      this.panelUpdater++;
    },

    changeTransactionPaidStatus() {
      if (event.target.id.includes("status-not-paid")) {
        this.transaction.status = "not_paid";
      } else if (event.target.id.includes("status-paid")) {
        this.transaction.status = "paid";
      }
    },
    async selectPerson(index, client, buyerOrSeller) {
      this.removeFieldInput(index, buyerOrSeller);
      if (!client) {
        return;
      }
      if (this.transaction[buyerOrSeller][index].type === "private") {
        this.transaction[buyerOrSeller][index] = {
          _id: client._id,
          customerId: client.customerId,
          email: client.email ? client.email : null,
          name: client.customerName ? client.customerName : null,
          address: client.address ? client.address : null,
          personalCode: client.personalCode ? client.personalCode : null,
          type: "private",
        };
      }
    },
    async chooseNewClientFromModal(client) {
      if (!client) return;

      await this.selectPerson(
          this.selectedIndex,
          client,
          this.selectedbuyerOrSeller
      );

      this.selectedIndex = null;
      this.selectedbuyerOrSeller = null;
    },
    addAnotherClient(buyerOrSeller) {
      if (
          this.transaction[buyerOrSeller] &&
          this.transaction[buyerOrSeller].length
      ) {
        this.transaction[buyerOrSeller].push({ type: "private" });
      } else {
        this.transaction[buyerOrSeller] = [];
        this.transaction[buyerOrSeller].push({ type: "private" });
      }
    },
    removeClient(index, buyerOrSeller) {
      if (index === 0) {
        this.transaction[buyerOrSeller] = [];
      } else {
        this.transaction[buyerOrSeller].splice(index, 1);
      }
    },
    removeFieldInput(index, buyerOrSeller) {
      Object.keys(this.transaction[buyerOrSeller][index])
          .filter((field) => field !== "type")
          .forEach(
              (field) => delete this.transaction[buyerOrSeller][index][field]
          );
    },
    openClientAddModal(index, buyerOrSeller) {
      this.clientAddModalOpen = true;
      this.selectedIndex = index;
      this.selectedbuyerOrSeller = buyerOrSeller;
    },
    closeClientAddModal() {
      this.clientAddModalOpen = false;
    },
    radioChanged(index, selectedValue, buyerOrSeller) {
      let existingClient = this.transaction[buyerOrSeller][index];

      if (selectedValue === "private" && existingClient._id) {
        this.transaction[buyerOrSeller][index] = existingClient;
      } else if (
          selectedValue === "company" &&
          existingClient.type === "company"
      ) {
        this.removeFieldInput(index, buyerOrSeller);
      }
      this.radioChangedValue++;
    },
    openStatusDropdown(object) {
      if (!this.hasAccess(this.transaction)) return;
      let button = document.getElementById("statusLabelSidepanel" + object._id);
      const rect = button.getBoundingClientRect();
      this.position = {};
      this.position.right = window.innerWidth - rect.right;
      this.position.top = rect.top + window.scrollY - 50;
      this.$emit("openStatusDropdown", object, this.position);
    },
    //Validation in progress, not using it right now
    isFormValid() {
      this.showErrors = this.getEmptyErrors();

      return this.validatePrivate
          .map((fieldName) => {
            return this.isFieldValid(fieldName);
          })
          .every((item) => item === true);
    },
    isFieldValid(fieldName) {
      this.transaction.seller.map((seller) => {
        if (!seller[fieldName]) {
          this.errors = [];
          this.errors[fieldName] = "FieldRequired";
        }
      });
    },
    getEmptyErrors() {
      return {
        name: "",
        personalCode: "",
        address: "",
      };
    },
    openEditComment() {
      this.editComment = true;
      this.$nextTick(() => {
        this.$refs.commentbox.focus();
      });
    },
    closeEditComment() {
      if (this.transaction.comment !== this.transactionComment) {
        this.transaction.comment = this.transactionComment;
        if (!this.isEdit) {
          this.submitTransaction();
        }
      }
      const closeEditComment = setInterval(() => {
        this.editComment = false;
        clearInterval(closeEditComment);
      }, 70);
    },
  },
};
</script>

<style lang="scss" scoped>
#transaction-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  width: unset;
  left: 0;
}

.transaction-panel {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  bottom: 0;
  background-color: #ffffff;
  z-index: 9;
  width: 640px;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  background: white;
  flex-direction: column;
  padding: 48px 32px 24px;
  width: inherit;
  border-top: 1px solid #e6e8ec;
  border-bottom: 1px solid #e6e8ec;

  h4 {
    max-width: 392px;
  }
}

.header-first-row {
  justify-content: space-between;
}

.header-second-row {
  gap: 8px;
}

.project-info {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 8px;
}

.main-info {
  padding: 32px 32px 64px;
}
.field-name {
  color: #75787a;
  font-family: "Inter", sans-serif;
}

.paid-label {
  font-weight: 500;
  color: black;
  background-color: #b4e7cf;
  border-radius: 5px;
  padding: 2px 4px;
  width: fit-content;
  cursor: pointer;
}

.not-paid-label {
  font-weight: 500;
  color: black;
  background-color: #ffb4b6;
  border-radius: 5px;
  padding: 2px 4px;
  width: fit-content;
  cursor: pointer;
}

/* .field-name {
  width: 208px;
  font-size: 14px;
  line-height: 20px;
} */

/* .field-value {
  min-width: 200px;
  max-width: 400px;
  font-size: 14px;
  line-height: 20px;
  margin-left: 12px;
} */
.field-name {
  color: #75787a;
  font-family: "Inter", sans-serif;
}

.field-name,
.field-value {
  width: 246px;
  font-size: 14px;
  line-height: 20px;
}

.modal-title {
  display: flex;
  align-items: center;
  font-size: 24px;
}

.header-button-container {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.header-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 6px;
  height: 24px;
  gap: 8px;

  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: #e7e7e7;
    cursor: pointer;
  }
}

.header-button-text {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0 !important;
}

.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  position: fixed;
  background: white;
  height: 97px;
  padding-right: 56px;
  width: 640px;
}

.transaction-details-edit-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 640px;
  left: 0;
  top: 125px;
  //height: 100%;
  box-shadow: inset 1px 0 0 #e6e8ec;
}

.transaction-details-edit:nth-child(odd) {
  background-color: #fafafa;
}
.transaction-details-edit:nth-child(even) {
  background-color: #ffffff;
}

.transaction-details-edit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 72px 32px 32px;

  h5 {
    margin-bottom: 24px;
  }
}

.edit-status-subcontainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.edit-status-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  background: #ffffff;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;

  .status-filter-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;
    width: fit-content;
    height: 52px;

    &.filter-item-not-paid {
      background: #ffb4b6;
      p {
        font-weight: 600;
      }
    }
    &.filter-item-paid {
      background: #b5e7cf;
      p {
        font-weight: 600;
      }
    }
  }
}

.edit-brokerage-fee-subcontainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.edit-brokerage-fee-type {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
}

.edit-brokerage-fee-row,
.edit-booking-date-row,
.edit-start-date-row,
.edit-contract-row {
  gap: 12px;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}
.edit-reminder-date-row {
  min-width: 262px;
}
.reminder-section {
  margin-top: 24px;
  margin-bottom: 12px !important;
}

.amount-minimum-fee {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
  width: 536px;
  height: 52px;
}

.edit-contract-party-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 536px;
  //height: 260px;
  background: #f4f5f7;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  margin-bottom: 24px;
}

.edit-contract-party-title {
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
}
.listing-field-row {
  gap: 24px;
}

.edit-contract-party-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
  width: 504px;
  //height: 180px;

  //flex: none;
  //order: 0;
  //align-self: stretch;
  //flex-grow: 0;
}
.sticky-footer-background {
  width: 640px;
  height: 112px;
}

.sticky-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 32px 32px 40px;
  gap: 16px;
  width: 640px;
  height: 112px;
  z-index: 100;
  background: #ffffff;
  position: fixed;
  bottom: 0;
}

.sticky-footer-row {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  width: 576px;
  height: 40px;
}

.object-top-header {
  padding-bottom: 12px;
  align-self: end;
}

.clear-button {
  display: flex;
  justify-content: flex-end;
  gap: 2px;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }
}

.comment-section {
  margin-top: 24px;
  border-top: 1px solid #e6e8ec;
}
.comment-container {
  margin-top: 26px;
}
.transaction-comment-header {
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #75787a;
  }
}
.transaction-name-row {
  gap: 12px;
  display: flex;
  align-items: center;
}

.transaction-textarea#textarea {
  white-space: pre-wrap;
  width: 485px;
  height: 96px;
}
.comment-text {
  white-space: pre-line;
  width: 485px;
  overflow-wrap: break-word;
  padding: 7px 0;
}
.comment-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 536px;
  border-radius: 8px;
  margin-bottom: 24px;
}
.comment-text-edit {
  display: flex;
  align-items: center;
  min-height: 52px;
  width: 504px;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  padding: 7px 11px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
  outline: none;
}
.hoverable-info {
  cursor: pointer;
  background-color: #f4f5f7;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;
  width: max-content;
  &:hover {
    background-color: #ff5c01;
    color: #ffffff;
    font-weight: bold;
  }
}
.open-new-tab-button {
  min-width: 20px;
  height: 20px;
  padding: 2px;
  cursor: pointer;
  border: 1px solid #e6e8ec;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid #000000;
    background: #000000;
    img {
      filter: invert(100%) sepia(22%) saturate(0%) hue-rotate(78deg)
        brightness(109%) contrast(101%);
    }
    span {
      padding: 2px;
      max-width: 7rem;
    }
  }
}
.open-new-tab-button span {
  max-width: 0;
  -webkit-transition: max-width 0.1s;
  transition: max-width 0.1s;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
  font-size: 12px;
}
.margin-up {
  margin-top: 24px;
}
</style>
